import invariant from 'tiny-invariant';

import { ClientEnvVars } from 'global';
import { Platform } from '~/services/phone-system/models/VoiceTokenRequest';
import { useMemo } from 'react';

/**
 * Returns a list of environmental variables that are safe to expose to the client.
 * If the environment is misconfigured, this function will throw an error.
 * @returns The client environment variables.
 */
export function getClientEnv(): ClientEnvVars {
  const vars: ClientEnvVars = {
    MARKETING_URL: import.meta.env.VITE_MARKETING_URL,
    TALK_URL: import.meta.env.VITE_APP_URL,
    TALK_OVERVIEW_URL: import.meta.env.VITE_TALK_OVERVIEW_URL,
    TALK_DOWNLOADS_URL: import.meta.env.VITE_TALK_DOWNLOADS_URL,
    A2P_10DLC_REGISTRATION_GUIDE_URL: import.meta.env
      .VITE_A2P_10DLC_REGISTRATION_GUIDE_URL,
    ROADMAP_URL: import.meta.env.VITE_ROADMAP_URL,
    DOCUMENTATION_URL: import.meta.env.VITE_DOCUMENTATION_URL,
    SOFT_PHONE_LOG_LEVEL: import.meta.env.VITE_SOFT_PHONE_LOG_LEVEL,
    APPLICATION_ID: import.meta.env.VITE_APPLICATION_ID,
    APPLICATION_VERSION: import.meta.env.VITE_APPLICATION_VERSION,
    PLATFORM: Platform[import.meta.env.VITE_PLATFORM],
    USE_SERVER_SENT_EVENTS:
      String(import.meta.env.VITE_USE_SERVER_SENT_EVENTS) === 'true'
        ? true
        : false,
    MOBILE_APP_ID_ANDROID: import.meta.env.VITE_MOBILE_APP_ID_ANDROID,
    MOBILE_APP_ID_IOS: import.meta.env.VITE_MOBILE_APP_ID_IOS,
    GOOGLE_TAG_ID: import.meta.env.VITE_GOOGLE_TAG_ID
  };

  const optional: string[] = [];

  // Ensure that all required environmental variables are present.
  Object.keys(vars).forEach((key) => {
    if (optional.includes(key)) return;
    invariant(key, `Missing environmental variable: ${key}`);
  });

  return vars;
}

/**
 * Allows to access the environment variables available to the client side.
 * @returns the environment variables.
 */
export function useClientEnv(): ClientEnvVars {
  return useMemo(() => getClientEnv(), []);
}
